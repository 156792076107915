import React, { useState, useEffect } from "react";
import FormInput from "./../../../components/formInput/Index";
import * as studentService from "./../../../services/StudentService";
import { PAGE_INDEX, PAGE_SIZE } from "./../../../constants/Pagination";
import Pagination from "./../../../components/pagination/Index";
import * as Alerts from "./../../../utils/Alerts";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import { useTokenData } from "../../../helper/useTokenData";
import { Link } from "react-router-dom";
import { useOrganization } from "../../../helper/useOrganization";
import { useMainContext } from "./../../../contexts/MainContext";

export default function SelectModal({ isOpen, handleStudentSelectModal, setSelectedStudent, organizationId }) {
  const { state, setState } = useMainContext();

  const [students, setStudents] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [loading, setLoading] = useState(false);

  const tokenData = useTokenData();
  const alias = useOrganization();

  let isAccessDetail = -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/detailinfo");

  useEffect(() => {
    getStudents(PAGE_INDEX, localStorage.rowCount);
  }, [isOpen]);

  const getStudents = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await studentService.getStudentsByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setStudents(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const isSelectRow = (rowId, fullName) => {
    rowId == selectedRow?.rowId ? setSelectedRow(null) : setSelectedRow({ rowId, fullName });
  };

  const changePage = (pageIndex) => {
    getStudents(pageIndex, localStorage.rowCount);
  };

  const handleAction = () => {
    if (selectedRow != null) setSelectedStudent(selectedRow);
    handleStudentSelectModal();
  };

  const Search = (e) => {
    e.preventDefault();
    getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal  m-block" : "modal"} id="selectStudentModalLabel" role="dialog" aria-labelledby="selectStudentModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Tələbələrin siyahısı
              <a href="javascript:void(0)" style={{ float: "right", color: "red" }} onClick={() => handleStudentSelectModal()}>
                <i className="material-icons">{"close"}</i>
              </a>
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <form onSubmit={Search}>
                <div className="col-md-6 navbar-form f-right">
                  <div className="form-group form-search">
                    <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                    <span className="material-input" />
                  </div>
                  <button className="btn btn-white btn-round btn-just-icon" type="submit">
                    <i className="material-icons">search</i>
                    <div className="ripple-container" />
                  </button>
                </div>
              </form>
              <div className="f-left">
                <Link to={`${alias}/student/add`} target="_blank">
                  <button className="btn btn-info f-right">Əlavə et</button>
                </Link>
              </div>
              <div className="card" data-background-color={state.theme}>
                <div className="card-content">
                  {loading ? (
                    <Loading />
                  ) : students?.length > 0 ? (
                    <div className="table-responsive">
                      <div className="table-max-height">
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th className="text-center">#</th>
                              <th>Tam adı</th>
                              {isAccessDetail && <th>Əlaqə nömrəsi</th>}
                              {/* <th>Email</th>
                            <th>Ünvan</th>
                            <th>Aktivlik</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {students.map((item, i) => (
                              <tr key={item?.idStudent} onClick={() => isSelectRow(item?.idStudent, `${item?.surname} ${item?.name} ${item?.middleName != null ? item?.middleName : ""}`)} className={selectedRow?.rowId === item?.idStudent ? "selectedRow" : ""}>
                                <td>
                                  <button
                                    style={{ margin: 0 }}
                                    className="btn btn-fill btn-success btn-select"
                                    onClick={() => {
                                      setSelectedStudent({ rowId: item?.idStudent, fullName: `${item?.surname} ${item?.name} ${item?.middleName != null ? item?.middleName : ""}` });
                                      handleStudentSelectModal();
                                    }}
                                  >
                                    Seç
                                  </button>
                                </td>
                                <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                                <td>{`${item?.surname} ${item?.name} ${item?.middleName != null ? item?.middleName : ""}`}</td>
                                {isAccessDetail && <td>{item?.phoneNumber}</td>}
                                {/* <td>{item?.emailAddress}</td>
                              <td>{item?.address}</td>
                              <td></td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={handleAction}>
              Seç
            </button>
            <button
              className="btn btn-fill btn-danger"
              onClick={() => {
                //    setSelectedStudent(null);
                handleStudentSelectModal();
              }}
            >
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
