import { get, post, put, deleteData, getForReturnFile } from "./core/Requests";

export const getDashboardHead = async (branchId) => {
  return await get(`/Helper/getDashboardHead/${branchId}`);
};

export const getPaymentTypes = async () => {
  return await get(`/Helper/getPaymentTypes`);
};

export const getEmployeePaymentTypes = async () => {
  return await get(`/Helper/getEmployeePaymentTypes`);
};

export const getCases = async () => {
  return await get(`/Helper/getCases`);
};

export const getMenus = async (userId) => {
  return await get(`/Helper/getMenus/${userId}`);
};

export const getDebtCalculationTypes = async () => {
  return await get(`/Helper/getDebtCalculationTypes`);
};

export const getCalendarInfoByFilter = async (data, branchId) => {
  return await post(`/Helper/getCalendarInfoByFilter/${branchId}`, data);
};

export const getContractStatuses = async () => {
  return await get(`/Helper/getContractStatuses`);
};

export const getContractCalculationDateTypes = async () => {
  return await get(`/Helper/getContractCalculationDateTypes`);
};

export const getDivisions = async () => {
  return await get(`/Helper/getDivisions`);
};

export const getEducationForms = async () => {
  return await get(`/Helper/getEducationForms`);
};

export const getSmsProviders = async () => {
  return await get(`/Helper/getSmsProviders`);
};

export const getMonths = async () => {
  return await get(`/Helper/getMonths`);
};

export const getColors = async (selected) => {
  return await get(`/Helper/getColors/${selected}`);
};

export const getTerminationReasions = async (type) => {
  return await get(`/Helper/getTerminationReasions/${type}`);
};

// export const addIncome = async (data) => {
//   return await post(`/Income`, data);
// };

// export const updateIncome = async (data) => {
//   return await put(`/Income`, data);
// };

// export const deleteIncome = async (id) => {
//   return await deleteData(`/Income/${id}`);
// };

// export const changeActive = async (id) => {
//   return await get(`/Income/changeActive/${id}`);
// };

export const getExportExcel = async (path) => {
  return await getForReturnFile(path);
};
