export const Now = (date) => {
  var today = new Date();
  today.setDate(today.getDate() + (date ?? 0));
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

export const NowTime = (date) => {
  var today = new Date();

  return today.toLocaleTimeString();
};

export const getLastNYears = (n) => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];
  for (let i = 0; i < n; i++) {
    years.push(currentYear - i); // Calculate the last 5 years
  }
  return years;
};