import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as employeeService from "./../../../services/EmployeeService";
import * as Alerts from "./../../../utils/Alerts";
import * as postService from "./../../../services/PostService";
import * as departmentService from "./../../../services/DepartmentService";
import * as salaryCalculationTypeService from "./../../../services/SalaryCalculationTypeService";
import * as attachmentService from "./../../../services/AttachmentService";
import * as salaryCalculationTypeGroup from "./../../../constants/SalaryCalculationTypeGroup";

import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import defaultImage from "./../../../assets/img/default-avatar.png";
import CommitButton from "./../../../components/commitButton/Index";

import { BaseAttachURL, FileRoutes } from "./../../../services/core/Urls";

//const defaultImage=require("./../../../assets/img/image_placeholder.jpg");

export default function Index() {
  const { state, setState } = useMainContext();

  const [isView, setIsView] = useState(false);
  const [employee, setEmployee] = useState();
  const [departments, setDepartments] = useState([]);
  const [posts, setPosts] = useState([]);
  const [salaryCalculationTypes, setSalaryCalculationTypes] = useState([]);
  // const [branchs, setBranchs] = useState([]);

  const [activeTab, setActiveTab] = useState("personal");

  const [image, setImage] = useState(defaultImage);
  const [attach, setAttach] = useState();

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idEmployee } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idEmployee && getEmployee();
    getDepartments();
    getPosts();
    getSalaryCalculationTypes();
    // getBranchs();
  }, []);

  const getEmployee = async () => {
    let result = await employeeService.getEmployee(idEmployee);
    if (result.success) {
      setEmployee(result.data);
      result.data?.profileImage && setImage(BaseAttachURL +"/file/" + FileRoutes.EmployeeRoute + result.data?.profileImage?.fileName+"/download");
    } else {
      Alerts.error(result.message);
    }
  };

  const getPosts = async () => {
    let result = await postService.getForSelect();
    if (result.success) {
      setPosts(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getDepartments = async () => {
    let result = await departmentService.getForSelect();
    if (result.success) {
      setDepartments(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getSalaryCalculationTypes = async () => {
    let result = await salaryCalculationTypeService.getSalaryCalculationTypes(salaryCalculationTypeGroup.TRAINER);
    if (result.success) {
      setSalaryCalculationTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateEmployee = async () => {
    let result = idEmployee ? await employeeService.updateEmployee({ ...employee, fkIdBranch: localStorage.branch }) : await employeeService.addEmployee({ ...employee, fkIdBranch: localStorage.branch });
    if (result?.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 3);
      formData.append("fkIdAttachmentSoruce", result.data?.idEmployee);
      formData.append("files", attach);
      await attachmentService.addAttachments(formData,"employees");

      Alerts.successWithBack(result.message, `${alias}/trainer`, navigate);
    } else {
      Alerts.error(result?.message);
    }
  };

  const handleInput = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setEmployee({ ...employee, [name]: e.value });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setAttach(event.target.files[0]);
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idEmployee ? (isView ? "Təlimçi məlumatları" : "Təlimçi məlumatlarının redaktəsi") : "Yeni təlimçi"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "personal" ? "active" : ""} onClick={() => setActiveTab("personal")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ŞƏXSİ MƏLUMATLAR
                    </a>
                  </li>
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "personal" ? "active" : "")}>
                    <div className="row">
                      <div className="col-sm-9">
                        <FormInput style={"col-sm-4"} label={"Ad"} name={"name"} value={employee?.name} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Soyad"} name={"surname"} value={employee?.surname} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Ata adı"} name={"middleName"} value={employee?.middleName} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Doğum tarixi"} name={"birthDate"} type={"date"} value={employee?.birthDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={employee?.phoneNumber} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Email"} name={"emailAddress"} value={employee?.emailAddress} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"FİN"} name={"id"} value={employee?.id} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-8"} label={"Ünvan"} name={"address"} value={employee?.address} onChange={handleInput} disabled={isView} />
                        <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={employee?.isActive} onChange={() => setEmployee({ ...employee, isActive: employee?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                      </div>
                      <div className="col-sm-3">
                        <span className="btn-file">
                          <div className="thumbnail">
                            <img src={image} style={{ minWidth: 150, maxWidth: 250, maxHeight: 250 }} />
                          </div>
                          <input type="file" accept=".jpg,.jpeg,.png,.tiff" onChange={onImageChange} disabled={isView}/>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      {/* <Select
                        placeholder={"Seç"}
                        style={"col-sm-4"}
                        label={"Filial"}
                        selectedValue={employee?.fkIdBranch}
                        changeSelectHandler={(e) =>
                          handleSelect(e, "fkIdBranch")
                        }
                        options={branchs.map((branch) => ({
                          label: branch?.branchName,
                          value: branch?.idBranch,
                        }))}
                      /> */}
                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Şöbə"} selectedValue={employee?.fkIdDepartment} changeSelectHandler={(e) => handleSelect(e, "fkIdDepartment")} options={departments.map((department) => ({ label: department?.departmentName, value: department?.idDepartment }))} isDisabled={isView} />

                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Vəzifə"} selectedValue={employee?.fkIdPost} changeSelectHandler={(e) => handleSelect(e, "fkIdPost")} options={posts.map((post) => ({ label: post?.postName, value: post?.idPost }))} isDisabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-4"} label={"Maaş hesablama növü"} selectedValue={employee?.fkIdSalaryCalculationType} changeSelectHandler={(e) => handleSelect(e, "fkIdSalaryCalculationType")} options={salaryCalculationTypes.map((salaryCalculationType) => ({ label: salaryCalculationType?.description, value: salaryCalculationType?.idSalaryCalculationType }))} isDisabled={isView} />

                      <FormInput style={"col-sm-4"} label={"Təhsil"} name={"education"} value={employee?.education} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"İş stajı"} name={"workExperience"} value={employee?.workExperience} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-12"} label={"Referans"} name={"referance"} value={employee?.referance} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                </div>

                <div className="row align-right">
                  {!isView && (
                   <CommitButton onClick={addOrUpdateEmployee} />
                  )}
                  <Link to={`${alias}/trainer`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
