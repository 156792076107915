import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as studentService from "./../../services/StudentService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import FinanceOperationListModal from "./../financeOperation/modal/ListModal";
import GroupListForStudentModal from "./../group/modal/GroupListForStudentModal";
import AttendanceListForStudentModal from "./../journal/modal/AttendanceListForStudentModal";
import StudentPassModal from "./modal/StudentPassModal";
import StudentDebtModal from "./modal/StudentDebtListModal";
import StudentNoteListModal from "./modal/StudentNoteListModal";
import StudentStatisticsModal from "./modal/StudentStatisticsModal";
import ContractStudentListByStudentModal from "./../contract/student/modal/ContractStudentListByStudentModal";
import ReviewListModal from "./../review/modal/ListModal";
import ActionButtons from "./../../components/actionButton/Index";
import { URL } from "./../../services/core/Urls";
import FilterTableRow from "./../../components/tableFilterRow/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [students, setStudents] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [loading, setLoading] = useState(false);
  const [isOpenFinanceOperationListModal, setIsOpenFinanceOperationListModal] = useState(false);
  const [isOpenGroupListModal, setIsOpenGroupListModal] = useState(false);
  const [isOpenAttendanceListModal, setIsAttendanceListModal] = useState(false);
  const [isContractStudentListByStudentModal, setIsContractStudentListByStudentModal] = useState(false);
  const [isOpenStudentNoteListModal, setIsOpenStudentNoteListModal] = useState(false);

  const [isOpenStudentPassModal, setIsStudentPassModal] = useState(false);
  const [isOpenStudentDebtListModal, setIsOpenStudentDebtListModal] = useState(false);
  const [isOpenReviewListModal, setIsOpenReviewListModal] = useState(false);
  const [isOpenStatisticsModal, setIsOpenStatisticsModal] = useState(false);
  const [studentPass, setStudentPass] = useState({});

  const [isOpenCombo, setIsOpenCombo] = useState(false);
  const [isOpenFilterCombo, setIsOpenFilterCombo] = useState(false);
  const [isFirstRendered, setIsFirstRendered] = useState(true);

  const alias = useOrganization();

  let isAccessDetail = -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/detailinfo");

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleFinanceOperationListModal = (e) => {
    e.stopPropagation();
    if (isOpenFinanceOperationListModal) setSelectedRow(0);
    setIsOpenFinanceOperationListModal(!isOpenFinanceOperationListModal);
  };

  const handleStatisticsModal = (e) => {
    e.stopPropagation();
    if (isOpenStatisticsModal) setSelectedRow(0);
    setIsOpenStatisticsModal(!isOpenStatisticsModal);
  };

  const handleGroupListModal = (e) => {
    e.stopPropagation();
    if (isOpenGroupListModal) setSelectedRow(0);
    setIsOpenGroupListModal(!isOpenGroupListModal);
  };

  const handleReviewListModal = (e) => {
    e.stopPropagation();
    if (isOpenReviewListModal) setSelectedRow(0);
    setIsOpenReviewListModal(!isOpenReviewListModal);
  };

  const handleStudentDebtListModal = (e) => {
    e.stopPropagation();
    if (isOpenStudentDebtListModal) setSelectedRow(0);
    setIsOpenStudentDebtListModal(!isOpenStudentDebtListModal);
  };

  const handleStudentNoteListModal = (e) => {
    e.stopPropagation();
    if (isOpenStudentNoteListModal) setSelectedRow(0);
    setIsOpenStudentNoteListModal(!isOpenStudentNoteListModal);
  };

  const handleAttendanceListModal = (e) => {
    e.stopPropagation();
    if (isOpenAttendanceListModal) setSelectedRow(0);
    setIsAttendanceListModal(!isOpenAttendanceListModal);
  };

  const handleStudentPassModal = (e) => {
    e.stopPropagation();
    if (isOpenStudentPassModal) setSelectedRow(0);
    setIsStudentPassModal(!isOpenStudentPassModal);
  };

  const handleContractStudentListByStudentModal = (e) => {
    e.stopPropagation();
    if (isContractStudentListByStudentModal) setSelectedRow(0);
    setIsContractStudentListByStudentModal(!isContractStudentListByStudentModal);
  };

  useEffect(() => {
    getStudents(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    if (isFirstRendered) {
      setIsFirstRendered(false);
    } else {
      if (state?.studentFilter != null) {
        const timer = setTimeout(() => {
          getStudents(PAGE_INDEX, localStorage.rowCount);
        }, 1500);

        return () => clearTimeout(timer);
      }
    }
  }, [state?.studentFilter]);

  const getStudents = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await studentService.getStudentsByBranch(pageIndex, pageSize, localStorage.branch, state?.studentFilter);
    if (result.success) {
      setStudents(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteStudent = async (id) => {
    await Alerts.confirmForStudentDelete(async function (confirmed) {
      if (confirmed) {
        let result = await studentService.deleteStudent(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getStudents(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idStudent) => {
    let result = await studentService.changeActive(idStudent);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getStudents(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getStudents(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  const AddFilter = (filterName) => {
    // setFilter({ common: filter.common, [filterName]: true });

    const updatedState =state?.studentFilter && filterName?.startsWith("is") ? 
    Object.fromEntries(Object.entries(state?.studentFilter).filter(([key]) => !key.startsWith("is"))) 
    : state?.studentFilter;

    setState({ ...state, studentFilter: { ...updatedState, [filterName]: true } });

    // getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  const handleFileChange = async (e) => {
    await Alerts.confirmForImport(async function (confirmed) {
      if (confirmed) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        let result = await studentService.importStudentsbyExcel(formData, localStorage.branch);
        if (result.success) {
          getStudents(PAGE_INDEX, localStorage.rowCount);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
      }
    });
  };

  return (
    <div className="col-md-12">
      {/* <label className="f-right">
        <div className={`dropdown ${isOpenCombo && "open"}`}>
          <button onClick={() => setIsOpenCombo(!isOpenCombo)} className="dropdown-toggle btn btn-success" data-toggle="dropdown">
            Digər <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/import") ? (
              <li>
                <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                  Məlumatların fayldan import et
                  <input type={"file"} accept=".xls, .xlsx" onChange={handleFileChange} onClick={(e) => (e.target.value = null)} style={{ display: "none" }} />
                </label>
              </li>
            ) : null}
            <li>
              <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                Export
                <input type={"button"} onClick={(e) => (window.location = URL.BaseURL + `/student/export/${localStorage.branch}`)} style={{ display: "none" }} />
              </label>
            </li>
          </ul>
        </div>
      </label> */}

      <ActionButtons deletePath={`student/delete`} editPath={`student/edit`} addPath={`student/add`} deleteRow={deleteStudent} selectedRow={selectedRow} handleFileChange={handleFileChange} isExsistOtherBtn={true} exportPath={"student/export"} />

      <label className="f-right">
        <div className={`dropdown ${isOpenFilterCombo && "open"}`}>
          <button onClick={() => setIsOpenFilterCombo(!isOpenFilterCombo)} className="dropdown-toggle btn open-blue" data-toggle="dropdown">
            Filter <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <a onClick={() => AddFilter("isAll")}>Hamısı</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotContract")}>Müqavilə bağlanmamış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isContract")}>Müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isActiveContract")}>Aktiv müqaviləsi olan tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isIndividualContract")}>Fərdi təlim müqaviləsi olan tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneActiveContract")}>Birdən artıq aktiv müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneContract")}>Birdən artıq müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isStopedContract")}>Müqaviləsi dayandırılmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneGroup")}>Birdən artıq qrupda olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotAnyGroup")}>Heç bir qrupda olmayanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isLessOneGroup")}>Azı bir qrupda olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornToday")}>Bu gün doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornTomorrow")}>Sabah doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornWeek")}>Bu həftə doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isAddedThisMonth")}>Bu ay əlavə olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isAddedThisWeek")}>Bu həftə əlavə olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isAddedThisDay")}>Bu gün əlavə olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isExsistDebt")}>Borcu olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isTerminationContract")}>Müqaviləsinə xitam verilənlər</a>
            </li>
          </ul>
        </div>
      </label>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">person_pin</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Tələbələrin siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getStudents(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={state?.studentFilter?.common} onChange={(e) => setState({ ...state, studentFilter: { ...state?.studentFilter, common: e.target.value } })} />
                  {/* <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} /> */}
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>FİN</th>
                      <th>Yaş</th>
                      {isAccessDetail && <th>Əlaqə nömrəsi</th>}
                      <th>Aktivlik</th>
                      <th className=" min-width-column"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FilterTableRow page={"studentFilter"} isAccessDetail={isAccessDetail} />
                    {students?.length > 0 ? (
                      students.map((item, i) => (
                        <tr key={item?.idStudent} onClick={() => isSelectRow(item?.idStudent)} className={selectedRow === item?.idStudent ? "selectedRow" : ""}>
                          <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                          <td>{item?.surname + " " + item?.name + " " + (item?.middleName != null ? item?.middleName : "")}</td>
                          <td>{item?.id}</td>
                          <td>{item?.age}</td>
                          {isAccessDetail && <td>{item?.phoneNumber}</td>}
                          <td>
                            <div className="togglebutton">
                              <label>
                                <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idStudent)} />
                                <span className="toggle" />
                              </label>
                            </div>
                          </td>
                          <td className="td-actions text-right min-width-column">
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/profile") ? (
                              <button
                                title="Şəxsi kabinet"
                                className="btn btn-danger"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  setStudentPass({ id: item?.idStudent, pass: item?.password, studentName: item?.surname + " " + item?.name + " " + (item?.middleName != null ? item?.middleName : "") });
                                  handleStudentPassModal(e);
                                }}
                              >
                                <i className="material-icons">key</i>
                              </button>
                            ) : null}
                              <button
                                title="Performans"
                                className="btn btn-secondary"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleStatisticsModal(e);
                                }}
                              >
                                <i className="material-icons">bar_chart</i>
                              </button>
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "financeoperation/3") ? (
                              <button
                                title="Ödənişlər"
                                className="btn btn-success"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleFinanceOperationListModal(e);
                                }}
                              >
                                <i className="material-icons">paid</i>
                              </button>
                            ) : null}
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "studentdebt") ? (
                              <button
                                title="Borclar"
                                className="btn btn-danger"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleStudentDebtListModal(e);
                                }}
                              >
                                <i className="material-icons">payments</i>
                              </button>
                            ) : null}
                            <button
                              title="Qruplar"
                              className="btn btn-secondary"
                              onClick={(e) => {
                                setSelectedRow(item?.idStudent);
                                handleGroupListModal(e);
                              }}
                            >
                              <i className="material-icons">groups</i>
                            </button>
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "contract/student") ? (
                              <button
                                title="Müqavilələr"
                                className="btn open-blue"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleContractStudentListByStudentModal(e);
                                }}
                              >
                                <i className="material-icons">receipt_long</i>
                              </button>
                            ) : null}
                            <button
                              title="Davamiyyət"
                              className="btn btn-warning"
                              onClick={(e) => {
                                setSelectedRow(item?.idStudent);
                                handleAttendanceListModal(e);
                              }}
                            >
                              <i className="material-icons">event</i>
                            </button>
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/note") ? (
                              <button
                                title="Qeydlər"
                                className="btn dark-green"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleStudentNoteListModal(e);
                                }}
                              >
                                <i className="material-icons">note</i>
                              </button>
                            ) : null}
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "review") ? (
                              <button
                                title="Rəylər"
                                className="btn"
                                onClick={(e) => {
                                  setSelectedRow(item?.idStudent);
                                  handleReviewListModal(e);
                                }}
                              >
                                <i className="material-icons">comment</i>
                              </button>
                            ) : null}
                            {isAccessDetail ? (
                              <Link to={`${alias}/student/view/${item?.idStudent}`}>
                                <button className="btn btn-info" title="Baxış">
                                  <i className="material-icons">visibility</i>
                                </button>
                              </Link>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={7}>
                        <NotFound />
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          )}
        </div>
      </div>
      {isOpenFinanceOperationListModal ? <FinanceOperationListModal isOpen={isOpenFinanceOperationListModal} handleViewModal={handleFinanceOperationListModal} header={"Tələbə ödənişləri"} idFinanceOperationType={3} filter={{ idStudent: selectedRow }} /> : null}
      {isOpenGroupListModal ? <GroupListForStudentModal isOpen={isOpenGroupListModal} handleViewModal={handleGroupListModal} header={"Qruplar"} studentId={selectedRow} /> : null}
      {isOpenAttendanceListModal ? <AttendanceListForStudentModal isOpen={isOpenAttendanceListModal} handleViewModal={handleAttendanceListModal} header={"Davamiyyət"} studentId={selectedRow} /> : null}
      {isOpenStudentPassModal ? <StudentPassModal isOpen={isOpenStudentPassModal} handleViewModal={handleStudentPassModal} header={"Şəxsi kabinet"} student={studentPass} alias={alias} /> : null}
      {isContractStudentListByStudentModal ? <ContractStudentListByStudentModal isOpen={isContractStudentListByStudentModal} handleViewModal={handleContractStudentListByStudentModal} header={"Tələbə müqavilələri"} studentId={selectedRow} /> : null}
      {isOpenStudentDebtListModal ? <StudentDebtModal isOpen={isOpenStudentDebtListModal} handleViewModal={handleStudentDebtListModal} header={"Borclar"} studentId={selectedRow} /> : null}
      {isOpenStudentNoteListModal ? <StudentNoteListModal isOpen={isOpenStudentNoteListModal} handleViewModal={handleStudentNoteListModal} header={"Qeydlər"} studentId={selectedRow} /> : null}
      {isOpenReviewListModal ? <ReviewListModal isOpen={isOpenReviewListModal} handleViewModal={handleReviewListModal} header={"Rəylər"} studentId={selectedRow} /> : null}
      {isOpenStatisticsModal ? <StudentStatisticsModal isOpen={isOpenStatisticsModal} handleViewModal={handleStatisticsModal} header={"Performans"} studentId={selectedRow} /> : null}

      {/* {isOpenViewModal ? <StudentViewModal isOpen={isOpenViewModal} handleViewModal={handleViewModal} student={student} /> : null} */}
    </div>
  );
}
