import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as authService from "../../../services/AuthService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";

import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, studentId, studentName }) {
  const { state, setState } = useMainContext();
  const [studentlogs, setStudentLogs] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStudentLogs(1, localStorage.rowCount);
  }, []);

  const getStudentLogs = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await authService.getStudentLogs(pageIndex, pageSize, studentId);
    if (result.success) {
      setStudentLogs(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };


  const changePage = (pageIndex) => {
    getStudentLogs(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Sistemə daxil olma tarixçəsi
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getStudentLogs(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : studentlogs?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tələbə</th>
                            <th>Tarix</th>
                            <th>IP</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentlogs.map((item, i) => (
                            <tr key={item}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{studentName}</td>
                              <td>{item?.date?.replace("T", " ")}</td>
                              <td>{item?.ip}</td>
                              <td>
                                <span className="select-color-ribbon" style={{ backgroundColor: item?.color, float: "left" }}></span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
