import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as groupService from "../../../services/GroupService";
import * as journalService from "../../../services/JournalService";
import * as helperService from "../../../services/HelperService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import Select from "../../../components/select/Index";
import * as date from "./../../../utils/DateNow";

import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, studentId }) {
  const { state, setState } = useMainContext();
  const [groups, setGroups] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedTime, setSelectedTime] = useState({});
  const [attendances, setAttendances] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGroups();
    getMonths();
  }, []);

  useEffect(() => {
    getAttendances(1, localStorage.rowCount);
  }, [selectedGroup, selectedTime]);

  const getGroups = async () => {
    let result = await groupService.getStudentGroups(0, 0, studentId);
    if (result.success) {
      setGroups(result.data.datas);
      // setSelectedGroup(result.data.datas[0]?.idGroup);
    } else {
      Alerts.error(result.message);
    }
  };

  const getMonths = async () => {
    let result = await helperService.getMonths();
    if (result?.success) {
      setMonths(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getAttendances = async (pageIndex, pageSize) => {
    let groupId = selectedGroup;
    let year = selectedTime?.year;
    let month = selectedTime?.month;
    setLoading(true);
    let result = await journalService.getAttendances(pageIndex, pageSize, { studentId, groupId, year, month });
    if (result.success) {
      setAttendances(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    getAttendances(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }} id="listAttendanceModalLabel">
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 f-left">
                <PageSizeSelect onChange={(e) => getAttendances(PAGE_INDEX, e.value)} />
              </div>
              <div className=" col-md-8 f-right z-100">
                <Select
                  placeholder={"Seç"}
                  style={"col-md-6"}
                  selectedValue={selectedGroup}
                  changeSelectHandler={(e) => {
                    setSelectedGroup(e.value);
                  }}
                  options={groups?.map((group) => ({ label: group?.groupName, value: group?.idGroup }))}
                />
                <Select
                  placeholder={"Seç"}
                  style={"col-md-3"}
                  selectedValue={selectedTime?.year}
                  changeSelectHandler={(e) => {
                    setSelectedTime({ ...selectedTime, year: e.value });
                  }}
                  options={date.getLastNYears(5)?.map((item) => ({ label: item, value: item }))}
                />
                <Select
                  placeholder={"Seç"}
                  style={"col-md-3"}
                  selectedValue={selectedTime?.month}
                  changeSelectHandler={(e) => {
                    setSelectedTime({ ...selectedTime, month: e.value });
                  }}
                  options={months?.map((item) => ({ label: item?.description, value: item?.idMonth }))}
                />
              </div>

              <div className="col-md-12">
                <div className="card-content">
                  {loading ? (
                    <Loading />
                  ) : attendances?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tələbə </th>
                            <th>Qrup </th>
                            <th>Təlim növü </th>
                            <th>Davamiyyət</th>
                            <th>Tarix</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attendances.map((item, i) => (
                            <tr key={item?.IdJournalDetail}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.fullName}</td>
                              <td>{item?.groupName}</td>
                              <td>{item?.trainingType}</td>
                              <td>{item?.participate == 0 ? "İştirak edib" : item?.participate == 1 ? "İştirak etməyib" : item.participate == 2 ? "İcazəli" : item?.participate == 3 ? "Qısa icazəli" : ""}</td>
                              <td>{item?.insertDate?.split("T")[0]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
