import React, { useState, useEffect } from "react";
import { Line, Radar } from "@ant-design/plots";

export default function LineChart({ data, color }) {

  const config = {
    data,
    xField: 'day',
    yField: 'value',
    seriesField: 'name',
    xAxis: {
      type: 'timeCat', // Ensures the x-axis is formatted as a time category
      title: {
        text: 'Gün',
        style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          fontSize: 13, // Optional: Adjust font size
        },
      },
      label: {
        style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          fontSize: 13, // Optional: Adjust font size
        },
      },
    },
    yAxis: {
      title: {
        text: 'Qiymətləndirmə',
        style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          fontSize: 13, // Optional: Adjust font size
        },
      },
      label: {
        style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          fontSize: 13, // Optional: Adjust font size
        },
      },
    },
    legend: {
      position: 'top',
    },
    smooth: false, // Optional: Makes the lines smooth
    // color: ['#FF6B6B', '#6BCB77', '#4D96FF'], // Colors for the categories
    tooltip: {
      shared: true, // Displays all series values for a given x-axis value
    },
    point: {
      size: 2, // Size of the points
      shape: 'circle', // Shape of the points (e.g., 'circle', 'square')
      style: {
        fill: 'white', // Inner color
        stroke: '#5B8FF9', // Border color
        lineWidth: 1, // Border thickness
      },
    },
  };

  return <Line {...config} />;

}
