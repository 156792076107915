import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as studentService from "../../../services/StudentService";
import Loading from "../../../components/loading/Index";
import * as date from "./../../../utils/DateNow";

import * as Alerts from "../../../utils/Alerts";
import RadarChart from "../../../components/chart/RadarChart";
import LineChart from "../../../components/chart/LineChart";
import NotFound from "../../../components/notfound/Index";

export default function ListModal({ isOpen, handleViewModal, header, studentId }) {
  const { state, setState } = useMainContext();
  const [studentPerformances, setStudentPerformances] = useState([]);
  const [studentPerformancesByDay, setStudentPerformancesByDay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-180), endDate: date.Now() });
  const [activeTab, setActiveTab] = useState("radar");

  useEffect(() => {
    if (activeTab == "radar") {
      getStudentPerformances();
    } else if (activeTab == "line") {
      getStudentPerformancesByDay();
    }
  }, [filter, activeTab]);

  const getStudentPerformances = async () => {
    setLoading(true);
    let result = await studentService.getStudentPerformances({ ...filter, idBranch: localStorage.branch, idStudent: studentId });
    if (result.success) {
      setStudentPerformances(result.data);
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const getStudentPerformancesByDay = async () => {
    setLoading(true);
    let result = await studentService.getStudentPerformancesByDay({ ...filter, idBranch: localStorage.branch, idStudent: studentId });
    if (result.success) {
      setStudentPerformancesByDay(result.data);
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const Search = (e) => {
    e.preventDefault();
    getStudentPerformances();
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <div className="row">
                    <form onSubmit={Search}>
                      <div className="navbar-form col-md-2 f-right">
                        <div className="form-group navbar-form">
                          <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                          <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                        </div>
                      </div>
                    </form>
                  </div>

                  <ul className="nav nav-pills nav-pills-warning">
                    <li style={{ width: "49%" }} className={activeTab == "radar" ? "active" : "notify-tab-noactive"} onClick={() => setActiveTab("radar")}>
                      <a href="javascript:void(0)" className={activeTab == "radar" ? "notify-tab-active" : ""} data-toggle="tab">
                        FƏNLƏR ÜZRƏ DƏYƏRLƏNDİRMƏ
                      </a>
                    </li>
                    <li style={{ width: "49%" }} className={activeTab == "line" ? "active" : "notify-tab-noactive"} onClick={() => setActiveTab("line")}>
                      <a href="javascript:void(0)" className={activeTab == "line" ? "notify-tab-active" : ""} data-toggle="tab">
                        GÜNLƏR ÜZRƏ QİYMƏT STATİSTİKASI
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={"tab-pane " + (activeTab === "radar" ? "active" : "")}>
                      <div className="row">
                        <div className="card-content pt-30 pl-15 pr-15">{loading ? <Loading /> : studentPerformances.length > 0 ? <RadarChart data={studentPerformances} /> : <NotFound />}</div>
                      </div>
                    </div>
                    <div className={"tab-pane " + (activeTab === "line" ? "active" : "")}>
                      <div className="row">
                        <div className="card-content pt-30 pl-15 pr-15">{loading ? <Loading /> : studentPerformances.length > 0 ? <LineChart data={studentPerformancesByDay} /> : <NotFound />}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
