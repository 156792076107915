import React, { useState, useEffect } from "react";
import { Radar } from "@ant-design/plots";

export default function RadarChart({ data, color }) {
  const maxItem = data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0]);


  const config = {
    data,
    xField: 'name',
    yField: 'value',
    // seriesField: '',
    meta: {
      value: {
        alias: 'Qiymətləndirmə',
        min: 0,
        max: maxItem?.value,
      },
    },
    xAxis: {
      line: null,
      label: {
        style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          fontSize: 13, // Optional: Adjust font size
        },
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `${v}`,
         style: {
          fontWeight: 'bold', // Make x-axis (categories) labels bold
          // fontSize: 14, // Optional: Adjust font size
        },
      },
    },
    area: {},
    point: {
      size: 2,
    },
    color: ['#FF6B6B'], // Colors for series
  };

  return <Radar {...config} />;
}
