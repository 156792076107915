import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as reportService from "./../../../services/ReportService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";

import { Link, useParams } from "react-router-dom";
import * as Alerts from "./../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import Loading from "./../../../components/loading/Index";
import ReportTable from "../../../components/table/ReportTable";
import * as date from "./../../../utils/DateNow";
import ActionButtons from "./../../../components/actionButton/Index";
import CaseSelectModal from "./../../case/modal/SelectModal";

export default function Index() {
  const { state, setState } = useMainContext();
  const [caseReports, setCaseReports] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedCase, setSelectedCase] = useState();
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });
  const [cases, setCases] = useState([]);
  const [isOpenCaseSelectModal, setIsOpenCaseSelectModal] = useState(false);

  const alias = useOrganization();
  const { idReport } = useParams();

  const headers = ["Kassaların ümumi hesabatı", "Kassanın günlər üzrə tam hesabatı", "Kassa mədaxil və məxaric hesabatı"];

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleCaseSelectModal = () => {
    setIsOpenCaseSelectModal(!isOpenCaseSelectModal);
  };
  useEffect(() => {
    idReport==1 && getCaseReports(state.currentPage, localStorage.rowCount);
  }, []);

  useEffect(() => {
    setCaseReports([]);
    selectedCase && getCaseReports(state.currentPage, localStorage.rowCount);
  }, [selectedCase]);

  const getCaseReports = async (pageIndex, pageSize) => {
    // if (filter?.fkIdCase != null && filter?.fkIdCase!=undefined) {
    filter.idCase = selectedCase?.rowId;
    setLoading(true);
    let result = await reportService.getCaseReportsByBranch(0, 0, idReport, { ...filter, idBranch: localStorage.branch });
    if (result.success) {
      setCaseReports(result?.data?.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
      //  }
    } else {
      //Alerts.error(result.message);
    }
  };

  const exportReport = async () => {
    filter.idBranch = localStorage.branch;
    let result = await reportService.exportCaseReportsByBranch(idReport, filter);

    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `${headers[idReport - 1]}.xlsx`;
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const Search = (e) => {
    e.preventDefault();
    getCaseReports(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons exportPath={` `} exportReport={exportReport} isExsistOtherBtn={true} />

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assessment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{headers[idReport - 1]}</h4>
          <div className="row">
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  {idReport != 1 ? (
                    <>
                      <input type="text" className="form-control ml-10 mr-10" disabled placeholder="Kassa adı" value={selectedCase?.description} />
                      <button type="button" className="btn btn-success btn-modal-filter" onClick={handleCaseSelectModal}>
                        Kassa seç
                      </button>
                    </>
                  ) : null}
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <ReportTable data={caseReports} reportType={"case"} idReport={idReport} pagination={pagination} />
            </div>
          )}
        </div>
      </div>
      {isOpenCaseSelectModal ? <CaseSelectModal isOpen={isOpenCaseSelectModal} handleSelectModal={handleCaseSelectModal} setSelected={setSelectedCase} /> : null}
    </div>
  );
}
