import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as journalService from "./../../../services/JournalService";
import * as groupService from "./../../../services/GroupService";
import * as groupMemberService from "./../../../services/GroupMemberService";
import * as employeeService from "./../../../services/EmployeeService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import NotFound from "./../../../components/notfound/Index";
import * as date from "./../../../utils/DateNow";
import CommitButton from "./../../../components/commitButton/Index";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import DatePicker from "react-datepicker";
import az from "date-fns/locale/az";
import { es } from "date-fns/locale/es";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [journal, setJournal] = useState({ registrationDate: date.Now(), trainerParticipated: 1 });
  const [groups, setGroups] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [activeTab, setActiveTab] = useState("general");
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [trainerOrAssistant, setTrainerOrAssistant] = useState("");

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idJournal } = useParams();

  useEffect(() => {
    getEmployees();
    idJournal && getJournal();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  useEffect(() => {
    getGroups(localStorage.branch);
  }, [localStorage.branch]);

  const getEmployees = async () => {
    let result = await employeeService.getForSelect();
    if (result.success) {
      setEmployees(result.data);
    } else {
      Alerts.error(result.message);
    }
  };
  const getGroups = async (branchId) => {
    let result = await groupService.getForSelect(branchId);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroupTrainerAndAssisent = async (groupId) => {
    if (groupId != null) {
      let result = await groupService.getGroupTrainerAndAssisent(groupId);
      if (result.success) {
        setJournal({ ...journal, fkIdGroup: groupId, fkIdTrainer: result?.data?.fkIdTrainer, fkIdAssistant: result?.data?.fkIdAssistant });
      } else {
        Alerts.error(result.message);
      }
    } else setJournal({ ...journal, fkIdGroup: groupId, fkIdTrainer: null, fkIdAssistant: null });
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const selectEmployee = (item) => {
    if (item != null) {
      if (trainerOrAssistant == "trainer") {
        setJournal({ ...journal, fkIdTrainer: item?.rowId });
      } else {
        setJournal({ ...journal, fkIdAssistant: item?.rowId });
      }
    }
  };

  const getGroupMembers = async (groupId) => {
    if (groupId != null) {
      let result = idJournal ? await journalService.getJournalDetails(0, 0, idJournal) : await groupMemberService.getGroupMembersByGroupForJournal(0, 0, groupId);
      if (result.success) {
        idJournal ? setGroupMembers(result.data.datas) : setGroupMembers(result.data.datas.map((item) => ({ ...item, participate: 0 })));
      } else {
        Alerts.error(result.message);
      }
    } else setGroupMembers([]);
  };

  const getJournal = async () => {
    let result = await journalService.getJournal(idJournal);
    if (result.success) {
      setJournal(result.data);
      getGroupMembers(result.data.fkIdGroup);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateJournal = async () => {
    setJournal({ ...journal, fkIdBranch: localStorage.branch });
    let jurnalItem = { ...journal, fkIdBranch: localStorage.branch };
    let result = idJournal ? await journalService.updateJournal({ journal: jurnalItem, journalDetails: groupMembers }) : await journalService.addJournal({ journal: jurnalItem, journalDetails: groupMembers });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/journal`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setJournal({ ...journal, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setJournal({ ...journal, [name]: e.value });
    if (name === "fkIdGroup") {
      getGroupMembers(e.value);
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idJournal ? (isView ? "Davamiyyət məlumatları" : "Davamiyyət məlumatlarının redaktəsi") : "Yeni davamiyyət"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      DAVAMİYYƏT MƏLUMATLARI
                    </a>
                  </li>
                  <li className={activeTab == "attendance" ? "active" : ""} onClick={() => setActiveTab("attendance")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      DAVAMİYYƏT
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-6"}
                        label={"Qrupun adı"}
                        required={true}
                        selectedValue={journal?.fkIdGroup}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdGroup");
                          getGroupTrainerAndAssisent(e.value);
                        }}
                        options={groups?.map((group) => ({ label: group?.groupName, value: group?.idGroup }))}
                        isDisabled={isView}
                      />
                      <FormInput style={"col-sm-4"} label={"Qeydiyyat tarixi"} required={true} name={"registrationDate"} type={"date"} value={journal?.registrationDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-2"} label={"Qeydiyyat saatı"} name={"registrationTime"} type={"time"} value={journal?.registrationTime} onChange={handleInput} disabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-6"} required={true} label={"Təlimçi"} selectedValue={journal?.fkIdTrainer} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainer")} options={employees.map((employee) => ({ label: `${employee?.surname} ${employee?.name} ${employee?.middleName}`, value: employee?.idEmployee }))} isDisabled={isView} />
                      {!isView && (
                        <div className="form-group col-sm-6">
                          <label className="label-control w-100"></label>
                          <button
                            className="btn btn-info"
                            disabled={isView}
                            onClick={() => {
                              setTrainerOrAssistant("trainer");
                              handleEmployeeSelectModal();
                            }}
                          >
                            Təlimçi seç
                          </button>
                        </div>
                      )}

                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Assistent"} selectedValue={journal?.fkIdAssistant} changeSelectHandler={(e) => handleSelect(e, "fkIdAssistant")} options={employees.map((employee) => ({ label: `${employee?.surname} ${employee?.name} ${employee?.middleName}`, value: employee?.idEmployee }))} isDisabled={isView} />
                      {!isView && (
                        <div className="form-group col-sm-6">
                          <label className="label-control w-100"></label>
                          <button
                            className="btn btn-info"
                            disabled={isView}
                            onClick={() => {
                              setTrainerOrAssistant("assitant");
                              handleEmployeeSelectModal();
                            }}
                          >
                            Assistent seç
                          </button>
                        </div>
                      )}

                      <FormCheckbox label={"Təlimçi iştirak edir"} style={"col-sm-12"} name={"trainerParticipated"} checked={journal?.trainerParticipated == 1 ? true : false} onChange={() => setJournal({ ...journal, trainerParticipated: journal?.trainerParticipated == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Assistent iştirak edir"} style={"col-sm-12"} name={"assistantParticipated"} checked={journal?.assistantParticipated == 1 ? true : false} onChange={() => setJournal({ ...journal, assistantParticipated: journal?.assistantParticipated == 1 ? 0 : 1 })} disabled={isView} />
                      <FormCheckbox label={"Dərsdə qiymətləndirmə aparıldı"} style={"col-sm-12"} name={"assessment"} checked={journal?.assessment == 1 ? true : false} onChange={() => setJournal({ ...journal, assessment: journal?.assessment == 1 ? 0 : 1 })} disabled={isView} />
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "attendance" ? "active" : "")}>
                    <div className="row">
                      <div className="card-content">
                        {groupMembers.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="text-center">#</th>
                                  <th>Tələbə</th>
                                  <th>Davamiyyət</th>
                                  {journal?.assessment == 1 ? <th>Qiymətləndirmə</th> : null}
                                  <th>Qeyd</th>
                                </tr>
                              </thead>
                              <tbody>
                                {groupMembers.map((item, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.fullName} </td>
                                    <td>
                                      <button
                                        className="btn btn-success mt-0 mb-0 p-5"
                                        onClick={(e) => {
                                          item.participate = 0;
                                          setGroupMembers([...groupMembers]);
                                        }}
                                        disabled={item.participate == 0 || isView}
                                      >
                                        İştirak edir
                                        {item.participate == 0 && <i className="material-icons">check</i>}
                                      </button>
                                      <button
                                        className="btn btn-info mt-0 mb-0  p-5"
                                        onClick={(e) => {
                                          item.participate = 2;
                                          setGroupMembers([...groupMembers]);
                                        }}
                                        disabled={item.participate == 2 || isView}
                                      >
                                        İcazəli
                                        {item.participate == 2 && <i className="material-icons">check</i>}
                                      </button>
                                      <button
                                        className="btn btn-warning mt-0 mb-0  p-5"
                                        onClick={(e) => {
                                          item.participate = 3;
                                          setGroupMembers([...groupMembers]);
                                        }}
                                        disabled={item.participate == 3 || isView}
                                      >
                                        Qısa icazəli
                                        {item.participate == 3 && <i className="material-icons">check</i>}
                                      </button>
                                      <button
                                        className="btn btn-danger mt-0 mb-0  p-5"
                                        onClick={(e) => {
                                          item.participate = 1;
                                          setGroupMembers([...groupMembers]);
                                        }}
                                        disabled={item.participate == 1 || isView}
                                      >
                                        İştirak etmir
                                        {item.participate == 1 && <i className="material-icons">check</i>}
                                      </button>
                                    </td>
                                    {journal?.assessment == 1 ? (
                                      <td style={{ maxWidth: 50 }}>
                                        <input
                                          type={"number"}
                                          className={" h-30 tbl-input"}
                                          value={item?.score}
                                          disabled={isView}
                                          onChange={(e) => {
                                            item.score = e.target.value;
                                            setGroupMembers([...groupMembers]);
                                          }}
                                        />
                                      </td>
                                    ) : null}
                                    <td style={{ minWidth: 250 }}>
                                      <input
                                        type={"text"}
                                        className={" h-30 tbl-input"}
                                        value={item?.note}
                                        disabled={isView}
                                        onChange={(e) => {
                                          item.note = e.target.value;
                                          setGroupMembers([...groupMembers]);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <NotFound />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateJournal} />}
                  <Link to={`${alias}/journal`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={selectEmployee} /> : null}
    </div>
  );
}
