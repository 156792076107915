import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import * as helperService from "./../../services/HelperService";
import * as studentService from "./../../services/StudentService";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import NotFound from "./../../components/notfound/Index";
import HeadCardItem from "../../components/dashboard/HeadCardItem";
import PieChart from "../../components/chart/PieChart";
import { useTranslation } from "react-i18next";
import { useStudent } from "../../helper/useStudent";
import RadarChart from "../../components/chart/RadarChart";
import LineChart from "../../components/chart/LineChart";
import * as date from "./../../utils/DateNow";

export default function Index() {
  const { state, setState } = useMainContext();
  const [dashboard, setDashboard] = useState();
  const [studentPerformances, setStudentPerformances] = useState([]);
  const [studentPerformancesByDay, setStudentPerformancesByDay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-180), endDate: date.Now() });

  const alias = useOrganization();
  const id = useStudent();
  const { t, i18n } = useTranslation()

  useEffect(() => {
    getDashboardHead();
    getStudentPerformances();
    getStudentPerformancesByDay();
  }, [localStorage.branch, t]);

  const getDashboardHead = async () => {
    if (localStorage.branch != undefined) {
      let result = await helperService.getDashboardHead(localStorage.branch);
      if (result.success) {
        setDashboard(result.data);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const getStudentPerformances = async () => {
    setLoading(true);
    let result = await studentService.getStudentPerformances({ ...filter, idBranch: localStorage.branch, idStudent: id.replace("/","") });
    if (result.success) {
      setStudentPerformances(result.data);
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const getStudentPerformancesByDay = async () => {
    setLoading(true);
    let result = await studentService.getStudentPerformancesByDay({ ...filter, idBranch: localStorage.branch, idStudent: id.replace("/","") });
    if (result.success) {
      setStudentPerformancesByDay(result.data);
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  return (
    <div className="ml-10 mr-10">
    <div className="row">
      <HeadCardItem header={t("dashboard.assignments")} theme={state.theme} count={dashboard?.assignmentCount} icon={"assignment"} desc={t('dashboard.description')} url={`${alias}${id}/assignment`} />
      <HeadCardItem header={t("dashboard.groups")} theme={state.theme} count={dashboard?.groupCount} icon={"groups"} desc={t('dashboard.description')} url={`${alias}${id}/group`} />
      <HeadCardItem header={t("dashboard.payments")} theme={state.theme} count={dashboard?.paymentCount} icon={"paid"} desc={t('dashboard.description')} url={`${alias}${id}/financeOperation/3`} />
      <HeadCardItem header={t("dashboard.reviews")} theme={state.theme} count={dashboard?.reviewCount} icon={"comment"} desc={t('dashboard.description')}  url={`${alias}${id}/review`} />
      <HeadCardItem header={t("dashboard.trainingMaterials")} theme={state.theme} count={dashboard?.trainingMaterialCount} icon={"attachment"} desc={t('dashboard.description')}  url={`${alias}${id}/trainingMaterial`} />
      <HeadCardItem header={t("dashboard.contracts")} theme={state.theme} count={dashboard?.studentContractCount} icon={"receipt_long"} desc={t('dashboard.description')}  url={`${alias}${id}/contract/student`} />
      <HeadCardItem header={t("dashboard.studentDebt")} theme={state.theme} count={dashboard?.studentDebt} icon={"payments"} desc={t('dashboard.description')}  url={``} />
    </div>
    <div className="row">
        <div className="col-md-6">
          <div className="card" data-background-color={state.theme}>
            <div className="card-header card-header-icon" data-background-color={state.theme}>
              <i className="material-icons">pie_chart</i>
            </div>
            <div className="card-content">
              <h4 className="card-title">Fənlər üzrə dəyərləndirmə</h4>
              <RadarChart data={studentPerformances} color={''} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card" data-background-color={state.theme}>
            <div className="card-header card-header-icon" data-background-color={state.theme}>
              <i className="material-icons">pie_chart</i>
            </div>
            <div className="card-content">
              <h4 className="card-title">Günlər üzrə qiymət statistikası</h4>
              <LineChart data={studentPerformancesByDay} color={''} />
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}
